import React from "react"
import { PageLayout } from "../components/Layout"
import TextSectionBackground from "../components/TextSectionBackground"
import { SectionHeader } from "../components/Typography"
import { useStaticQuery, graphql } from "gatsby"
import ImageRow from "../components/ImageRow"
import SEO from "../components/SEO"

const Lodge = () => {
  const {
    contentfulActivityPage: { line },
  } = useStaticQuery(graphql`
    {
      contentfulActivityPage {
        line {
          header
          description {
            json
          }
          imageDesction {
            fluid(maxWidth: 610, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  `)

  return (
    <PageLayout>
      <SEO 
      title="Safari Activities" 
      description="Experience adventurous safari activities at Mavela Game. Enjoy walking safaris, game drives and diverse beauty of the Zululand bush."
      />
      <TextSectionBackground pa="4% 10%">
          <SectionHeader>Activities</SectionHeader>
        {line.map((about: any, i: number) => (
          <ImageRow
            key={about.header}
            reverse={!!(i % 2)}
            pic={about.imageDesction.fluid}
            header={about.header}
            text={about.description.json}
          ></ImageRow>
        ))}
      </TextSectionBackground>
    </PageLayout>
  )
}

export default Lodge
